import React from "react";
import { Sidebar } from "../../components/sidebar/sidebar";
import { useGetSelfQuery } from "../../store/reducers/api";
import "./dashboard.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Applications } from "./applications/applications";
import { Account } from "./account/account";
import { Chats } from "./chats/chats";
import { Chat } from "./chats/chat";
import { Users } from "./users/users";
import { User } from "./users/user";
import { Psychic } from "./psychics/psychic";
import { Psychics } from "./psychics/psychics";
import { Transfers } from "./transfers/transfers";
import { Reviews } from "./reviews/reviews";
import { Reports } from "./reports/reports";
import { ChatValueCodes } from "./chatValueCodes/chatValueCodes";
import BlockUser from "./blockUser";
import {Analytics} from "./analytics/analytics";
import {PsychicPayouts} from "./grouped_payouts/grouped_payouts";


export function Dashboard() {
  const { data: user } = useGetSelfQuery(null, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  return (
    <div id="dashboard">
      <div id="sidebar-div" className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark">
        <Sidebar />
      </div>
      <div id="dashboard-page">
        <Routes>
          {user && user.user_role === "admin" && (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Navigate
                    to={{
                      pathname: "/applications",
                    }}
                  />
                }
              />
              <Route path="/applications" element={<Applications />} />
              <Route path="/analytics" element={<Analytics />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/chats" element={<Chats />} />
              <Route path="/chat/:chatID" element={<Chat />} />
              <Route path="/psychics" element={<Psychics />} />
              <Route path="/psychic/:userID" element={<Psychic />} />
              <Route path="/users" element={<Users />} />
              <Route path="/user/:userID" element={<User />} />
              <Route path="/transfers" element={<Transfers />} />
              <Route path="/grouped-payouts" element={<PsychicPayouts />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/block-user" element={<BlockUser />} />
              <Route path="/chat-value-codes" element={<ChatValueCodes />} />
            </React.Fragment>
          )}
          {user && user.user_role === "psychic" && (
            <React.Fragment>
              <Route
                path="/"
                element={
                  <Navigate
                    to={{
                      pathname: "/account",
                    }}
                  />
                }
              />
              <Route path="/account" element={<Account />} />
            </React.Fragment>
          )}
        </Routes>
      </div>
    </div>
  );
}

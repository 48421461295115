import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import {useGetGroupedPayoutsQuery} from "../../../store/reducers/adminApi";

const STRIPE_DASHBOARD_URL = 'https://dashboard.stripe.com/connect/accounts/';

export function PsychicPayouts() {
    const { data: psychics, isLoading, error } = useGetGroupedPayoutsQuery(null);
    console.log('psychics data', psychics, isLoading, error);


    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(amount / 100);
    };



    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error loading psychic payouts</div>;

    return (
        <div className="container mt-4">
            <h2>Psychic Payouts Overview</h2>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Psychic</th>
                        <th>Stripe Account</th>
                        <th>Total Paid Out</th>
                        <th>Total Owed</th>
                        <th>Transactions</th>
                        <th>Failures</th>
                    </tr>
                </thead>
                <tbody>
                    {psychics && psychics.map((psychic) => (
                        <tr key={psychic.userID}>
                            <td>
                                <Link to={`/psychic/${psychic.userID}`}>
                                    {psychic.email}
                                </Link>
                            </td>
                            <td>
                                {psychic.stripeAccountId ? (
                                    <a
                                        href={`${STRIPE_DASHBOARD_URL}${psychic.stripeAccountId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {psychic.stripeAccountId}
                                    </a>
                                ) : (
                                    <span className="text-danger">No Stripe Account</span>
                                )}
                            </td>
                            <td>
                                {formatCurrency(psychic.totalPaidOut)}
                            </td>
                            <td className={psychic.totalOwed > 0 ? 'text-danger' : ''}>
                               {formatCurrency(psychic.totalOwed)}
                            </td>
                            <td>
                                {psychic.transactionCount}
                            </td>
                            <td className={psychic.failureCount > 0 ? 'text-danger' : ''}>
                                {psychic.failureCount}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className="mt-4">
                <h4>Summary</h4>
                <p>
                    Total Psychics: {psychics?.length || 0}<br />
                    Total Amount Paid: {formatCurrency(psychics?.reduce((sum, p) => sum + p.totalPaidOut, 0) || 0)}<br />
                    Total Amount Owed: {formatCurrency(psychics?.reduce((sum, p) => sum + p.totalOwed, 0) || 0)}

                </p>
            </div>
        </div>
    );
}
